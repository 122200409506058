import React, { Fragment } from "react"
import PageLayout from '../../../layouts/page-layout';
import {graphql} from "gatsby";

const Page = ({data}) => {
  return (
    <PageLayout
      data={data} title="Computer Clinic"
      description="Near Woolwich, Greater London"
    >
      <p>We supply no fix no fee computer repair service to London and surrounding areas. We do not charge for checking and quoting a repair price</p>
      <div className="row">

        <div className="span5">
          <p><strong>Address:</strong></p>
          <address>
            Computer Clinic<br />
            117 plumstead common road <br />
            London<br /> se182uq<br />
          </address>

          <span title="Phone"><strong>Phone:</strong> 02083174672</span><br />
		<span title="Website"><strong>Website:</strong><br />
				www.pcs4you.co.uk
				</span><br />
		<span title="Website"><strong>Twitter:</strong><br />
				</span><br />
        </div>

        <div className="span3">
          <img alt="Recommended site"
               src="/img/badges/recomm2-200.png"/><br />

        </div>
      </div>


    </PageLayout>
  );
};

export default Page;

export const query = graphql`
  query {
    profile: profileYaml {
      ...ProfileFragment
    }
    site {
      siteMetadata {
        title,
        showThemeLogo
      }
    }
  }
`
